import React from 'react';
import { Modal, Button } from 'react-bulma-components';

export default ({ isOpen, onClose, onAcknowledge }) => {
    return (
        <>
            <Modal
                show={isOpen}
                onClose={() => onClose(false)}
                closeOnBlur={true}
            >
                <Modal.Card style={{ borderRadius: '10px' }}>
                    {/* Removed the Modal.Card.Header */}
                    <Modal.Card.Body style={{ padding: '2px', height: '500px' }} className='is-primary-bg'>
                        <iframe
                            src="https://trainer.astute.health/terms?removeFooter=true"
                            title="Astute Health Terms and Conditions"
                            width="100%"
                            height="100%"
                            style={{ border: 'none' }}
                        />
                    </Modal.Card.Body>
                    <Modal.Card.Footer style={{ backgroundColor: '#fff', padding: '1rem', display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ flex: 1, marginRight: '0.5rem' }}>
                            <Button
                                textColor='white'
                                className='is-primary-light-bg-only'
                                fullwidth
                                onClick={() => onClose(false)}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div style={{ flex: 1, marginLeft: '0.5rem' }}>
                            <Button
                                color="primary"
                                fullwidth
                                onClick={() => onAcknowledge()}
                            >
                                <b>I Acknowledge</b>
                            </Button>
                        </div>
                    </Modal.Card.Footer>
                </Modal.Card>
            </Modal>
        </>
    );
};
