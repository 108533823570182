import { toast } from 'react-toastify';
export default class {
    static toastLoading = () => {
        toast.loading("Connecting, try momentarily.", {
            // style: { color: '#0D98BA' }
        });
        setTimeout(() => {
            toast.dismiss();
        }, 2000);
    }
}