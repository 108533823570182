import React from 'react';
import { Navigation } from '../components';

export default class Privacy extends React.Component {
    render() {
        return (
            <>
                <Navigation.Header isLoggedIn={false} />
                <div className="container" style={{ minHeight: '700px', marginTop: '20px' }}>
                    <div style={{ background: "white", padding: "45px", marginRight: "10px", marginLeft: "10px" }}>
                        <h2 className="title">Astute Health Privacy and Cookie Policy</h2>
                        <p>This Policy outlines how we handle your personal data and the use of cookies and similar technologies on the Astute Health website (the "Website"). It applies to all users and visitors of our Website.</p>

                        <br />
                        <h2 className="subtitle">1. Collection and Use of Personal Data</h2>
                        <ul>
                            <li><u>Data Processing:</u> We use your data primarily to provide and improve our healthcare and AI-related services. This includes but is not limited to appointment scheduling, personalized healthcare recommendations, and AI-driven analysis.</li>
                            <li><u>Marketing and Advertising:</u> Currently, we do not use your personal data for marketing or advertising purposes. However, if this policy changes, we will update you and provide options to opt-out.</li>
                        </ul>

                        <br />
                        <h2 className="subtitle">2. Cookies and Similar Technologies</h2>
                        <p>Our Website currently does not use cookies to enhance user experience, analyze Website traffic, and for website functionality. You can control the use of cookies at the individual browser level.</p>

                        <br />
                        <h2 className="subtitle">3. Your Rights and Choices</h2>
                        <ul>
                            <li><u>Access and Control:</u> If you need data access, corrected, or deleted that we hold, currently you need to contact us for more information on this outside of what controls you have in the application itself.</li>
                            {/* <li>Opt-Out: You can opt-out of data processing for specific purposes, such as marketing or AI-driven analysis, where applicable.</li> */}
                            <li><u>Consent Withdrawal:</u> Where our use of your data is based on consent, you have the right to withdraw that consent at any time.</li>
                        </ul>

                        <br />
                        <h2 className="subtitle">4. Data Security and Retention</h2>
                        <p>We implement a variety of security measures to maintain the safety of your personal data, including encryption and access control. We retain your personal data for as long as necessary to provide you with our services or as required by law.</p>

                        <br />
                        <h2 className="subtitle">5. Changes to this Policy</h2>
                        <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page and updating the "effective date" at the top.</p>

                        <br />
                        <h2 className="subtitle">Contact Us</h2>
                        <p>For any questions or concerns regarding this policy or our privacy practices, please contact us at contactus@astute.health.</p>
                    </div>
                </div>
                <Navigation.Footer isLoggedIn={false} />
            </>
        );
    }
}