import React, { useEffect, useState } from "react";
import { Heading, Container, Button, Section, Columns } from "react-bulma-components";
import Modals from "../../components/Modals";
import { AppApi, JsUtils } from "../../services";
import { Loading } from "../../components";
import { toast } from "react-toastify";
const appApi = new AppApi();

export default (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [historyResults, setHistoryResults] = useState([]);
    const [isLoadMore, setIsLoadMore] = useState(false);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalData, setModalData] = useState(null);

    useEffect(() => {
        getHistory()
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getHistory = async () => {
        setIsLoading(true);
        try {
            const responseData = await appApi.getUserTestSessions(lastEvaluatedKey);
            console.log(responseData)
            if (!!responseData?.data?.lastEvaluatedKey) {
                setIsLoadMore(true);
                setLastEvaluatedKey(responseData.data.lastEvaluatedKey);
            } else {
                setIsLoadMore(false);
            }

            // @ts-ignore
            setHistoryResults(prevHistoryResults => [...prevHistoryResults, ...responseData?.data?.Items]);
        } catch (e) { toast.error("Error retrieving history."); console.log(e) }
        setIsLoading(false);
    }

    const onLoadMoreClick = () => {
        getHistory();
    }

    const handleViewClick = (item) => {
        setModalData(item);
        setIsModalVisible(true);
    }

    const HistoryTable = ({ data }) => {
        return (
            <Container className="table-container">
                <table className="table is-fullwidth is-hoverable">
                    <thead>
                        <tr>
                            <th className="centered-cell">Id</th>
                            <th className="centered-cell">Correct</th>
                            <th className="centered-cell">Incorrect</th>
                            <th className="centered-cell">Score</th>
                            <th className="centered-cell">Session Time (minutes:seconds)</th>
                            <th className="centered-cell">Created</th>
                            <th className="centered-cell">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td className="centered-cell">{index + 1}</td>
                                <td className="centered-cell">{item.passedTests}</td>
                                <td className="centered-cell">{item.failedTests}</td>
                                <td className="centered-cell">{`${item.overallPercentScore.toFixed(2)}%`}</td>
                                <td className="centered-cell">{Math.floor(item.sessionTime / 60)}:{String(item.sessionTime % 60).padStart(2, '0')}</td>
                                <td className="centered-cell">{JsUtils.formatDate(item.created)}</td>
                                <td className="centered-cell"><button className="button is-primary" onClick={() => handleViewClick(item)}>View</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Container>
        );
    }

    const NoHistoryMessage = () => (
        <div className="has-text-centered">
            <p>You have no history of sessions completed.</p>
        </div>
    );

    /**
     * @param {*} param0 
     * TODO: move to component
     */
    const LoadMoreButton = ({ onClick, isLoadMore }) => (
        <button
            className={`button is-primary is-medium load-more-button ${isLoading ? 'is-loading' : ''}`}
            onClick={onClick}
            disabled={isLoadMore}
        > Load More </button>
    );

    return (
        <div>
            <Container>
                <div className="columns is-mobile" style={{ marginTop: '0px' }}>
                    <div className="column is-full-mobile">
                        <Button className="is-fullwidth-mobile is-small-mobile" color="primary" onClick={() => (window.location.href = '/')}>
                            Start New Session
                        </Button>
                    </div>
                    <div className="column is-three-quarters-mobile has-text-centered">
                        <Heading className="is-size-4-mobile is-size-2-tablet" weight="semibold">History Results</Heading>
                    </div>
                    <div className="column is-hidden-mobile"></div> {/* Hide this column on mobile */}
                </div>
                {/* Conditional rendering based on historyResults */}
                {historyResults?.length > 0 ? (
                    <>
                        <HistoryTable data={historyResults} />
                        {isLoadMore && <LoadMoreButton disabled={isLoadMore} onClick={onLoadMoreClick} />}
                        {!!isLoading && <Loading visible={isLoading} />}
                        <Modals.HistoryViewModal show={isModalVisible} data={modalData} onClose={() => setIsModalVisible(false)} />
                    </>
                ) : (
                    <NoHistoryMessage />
                )}
            </Container>
        </div>
    );
}