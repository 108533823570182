import React from "react";
import { Oval } from 'react-loader-spinner';

export default (props) => {
    return (
        <div
            className="has-text-centered"
            style={{
                alignContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
                width: '100%'
            }}>
            <Oval
                height={80}
                width={80}
                color="#0D98BA"
                wrapperStyle={{
                    alignContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center',
                    display: 'block'
                }}
                wrapperClass="is-primary-txt"
                visible={props.visible}
                ariaLabel='oval-loading'
                secondaryColor="#0d97ba88"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />
        </div>
    )
}