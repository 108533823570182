import React, { useState } from "react";
import { Navigation } from '../components';
import { Login, History } from "../containers";

export default (props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    return (
        <>
            <Navigation.Header isLoggedIn={isLoggedIn} />
            {/* fill content between container and footer */}
            <div className="is-primary-white-background home-wrapper">
                {!isLoggedIn && <Login onLoggedIn={() => { setIsLoggedIn(true) }} />}
                {!!isLoggedIn && <History />}
            </div>
            <Navigation.Footer />
        </>
    )
}