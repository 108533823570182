import React from "react";
import { Modal } from 'react-bulma-components';

export default (props) => {
    return (
        <>
            <Modal
                // {...args}
                show={props.show}
                onClose={() => {
                    props.onClose();
                }}
                closeOnBlur={true}
                showClose={false}
            >
                <Modal.Card style={{ padding: '20px', width: '75%' }}>
                    <Modal.Card.Header showClose>
                        <Modal.Card.Title>CodeCapture Info</Modal.Card.Title>
                    </Modal.Card.Header>
                    <Modal.Card.Body>
                        <p>{props.text}</p>
                    </Modal.Card.Body>
                </Modal.Card>
            </Modal>
        </>
    )
}