import React from "react";
import { Button, Modal, Form } from 'react-bulma-components';

export default (props) => {
    return (
        <>
            <Modal
                // {...args}
                show={props.show}
                onClose={() => {
                    props.onClose();
                }}
                closeOnBlur={true}
                showClose={false}
            >
                <Modal.Card style={{ padding: '20px' }}>
                    <Modal.Card.Header showClose>
                        <Modal.Card.Title>Verify Access Token</Modal.Card.Title>
                    </Modal.Card.Header>
                    <Modal.Card.Body>
                        <Form.Field>
                            <Form.Control>
                                <Form.Input
                                    onChange={(event) => { props.onInputChange(event) }}
                                    placeholder="Access Code"
                                    type="text"
                                />
                            </Form.Control>
                        </Form.Field>
                    </Modal.Card.Body>
                    <Modal.Card.Footer renderAs={Button.Group} align="right" hasAddons>
                        <Button
                            className="is-primary-bg is-white-txt"
                            style={{ width: '100%' }}
                            onClick={props.onVerifyClick}>
                            Verify
                        </Button>
                    </Modal.Card.Footer>
                </Modal.Card>
            </Modal>
        </>
    )
}