import React from 'react';
import { Navigation } from '../components';

export default class Terms extends React.Component {
    getQueryParams() {
        const searchParams = new URLSearchParams(window.location.search);
        return {
            iframe: searchParams.get('iframe') === 'true',
            removeFooter: searchParams.get('removeFooter') === 'true',
        };
    }

    render() {
        const { iframe, removeFooter } = this.getQueryParams();

        return (
            <>
                {!iframe && <Navigation.Header isLoggedIn={false} />}
                <div className="container terms-container" style={removeFooter ? { marginTop: "0px" } : {}}>
                    <div className="terms-content">
                        {removeFooter && (
                            <div>
                                <span className="subtitle is-primary-txt"><b>Welcome to TrainerⓇ,</b></span>
                                <span className=""><b> a hyper-realistic coding experience designed to help excel in your health information management journey.</b></span>
                                <br />
                                <br />
                                <b>To complete your Registration, please read and acknowledge the Terms of Service below.</b>
                                <br />
                                <br />
                            </div>
                        )}

                        {removeFooter && (<h2 style={{ marginBottom: '0px' }} className="subtitle"><b>Astute Health "Trainer"Ⓡ Terms of Service</b></h2>)}
                        {!removeFooter && (<h2 style={{ marginBottom: '0px' }} className="title">Astute Health "Trainer"Ⓡ Terms of Service</h2>)}
                        <p className="terms-paragraph text-sm text-gray-600" style={{ marginTop: '0px', marginBottom: '20px' }}>
                            <i className='is-primary-txt'>Last Updated 2024-11-12</i>
                        </p>
                        <p className="terms-paragraph">
                            Welcome to Astute Health "Trainer"Ⓡ. By using our website, applications, and services (collectively called "Services"), you agree to these Terms of Service ("Terms"). Please read them carefully.
                        </p>

                        <h3 className="subtitle" style={{}}>1. Acceptance of Terms</h3>
                        <p className="terms-paragraph">
                            By accessing or using our Services, you agree to follow these Terms. If you do not agree with any part, please do not use our Services.
                        </p>

                        <h3 className="subtitle" style={{}}>2. Changes to Terms</h3>
                        <p className="terms-paragraph">
                            We may update these Terms occasionally. We will post any changes on our website, so please check back regularly.
                        </p>

                        <h3 className="subtitle" style={{}}>3. Information We Collect</h3>
                        <p className="terms-paragraph">
                            To personalize your experience, we need certain information:
                        </p>
                        <ul className="terms-list terms-paragraph">
                            <li>Demographic Information: Your first and last name, state and ZIP code.</li>
                            <li>Professional Information: Your areas of coding expertise, certifications, and related details.</li>
                            <li>Usage Information: Any tools or references that you use during your "Trainer"Ⓡ experience, including physical code books, websites, encoders, computer-assisted coding and AI software.</li>
                        </ul>
                        <p className="terms-paragraph">
                            Providing accurate and current information is critical, as it helps us tailor the Services to your user experience.
                        </p>

                        <h3 className="subtitle" style={{}}>4. Creating an Account</h3>
                        <ul className="terms-list terms-paragraph">
                            <li>Accuracy: You must provide truthful and up-to-date information when creating an account. Your individual identifying information (first and last name) will not be shared with anyone</li>
                            <li>Security: Your login email address is your account. You are responsible for all activities under your account.</li>
                            <li>Responsibility: Do not share your account details with others.</li>
                        </ul>

                        <h3 className="subtitle" style={{}}>5. Using Our Services</h3>
                        <p className="terms-paragraph">
                            Allowed Use: Use the Services only for lawful purposes.
                        </p>
                        <p className="terms-paragraph">
                            Prohibited Actions:
                        </p>
                        <ul className="terms-list terms-paragraph">
                            <li>Do not copy, share, sell, or distribute any content or materials from our Services without permission.</li>
                            <li>Do not overload or harm our systems.</li>
                            <li>Do not interfere with others' use of the Services.</li>
                        </ul>

                        <h3 className="subtitle" style={{}}>6. Intellectual Property</h3>
                        <p className="terms-paragraph">
                            All content, features, and functionality on the Services are owned by Astute Health and are protected by law.
                        </p>

                        <h3 className="subtitle" style={{}}>7. Termination of Access</h3>
                        <p className="terms-paragraph">
                            We may suspend or terminate your access to the Services if you violate these Terms or engage in misconduct.
                        </p>

                        <h3 className="subtitle" style={{}}>8. Limitation of Liability</h3>
                        <p className="terms-paragraph">
                            Astute Health is not responsible for any loss or damage resulting from your use of the Services or from sharing your personal information.
                        </p>

                        <h3 className="subtitle" style={{}}>9. Disclaimer</h3>
                        <p className="terms-paragraph">
                            The Services are provided "as is" without warranties of any kind. Use them at your own risk.
                        </p>

                        <h3 className="subtitle" style={{}}>10. Governing Law</h3>
                        <p className="terms-paragraph">
                            These Terms are governed by the laws of Texas.
                        </p>

                        <h3 className="subtitle" style={{}}>11. Contact Us</h3>
                        <p className="terms-paragraph">
                            If you have questions about these Terms, please contact us at <a href="mailto:info@astute.health" className="is-primary-txt">info@astute.health</a>.
                        </p>
                    </div>
                </div>

                {!removeFooter && <Navigation.Footer isLoggedIn={false} />}
            </>
        );
    }
}