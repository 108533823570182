import React from 'react';
import { Modal } from 'react-bulma-components';

const AboutTrainerModal = ({ show, onClose }) => {
    return (
        <Modal
            show={show}
            onClose={onClose}
            closeOnBlur={true}
            showClose={false}
        >
            <Modal.Content>
                <div className="box" style={{ maxWidth: '800px', margin: 'auto' }}>
                    {/* Title */}
                    <h1 className="title has-text-centered mb-5">
                        About Astute Health Trainer®
                    </h1>

                    {/* Main content */}
                    <div className="content">
                        <p className="mb-4">
                            <b className='is-primary-txt'>Trainer®</b> is designed to evaluate your ability to code a sample of
                            medical charts in a realistic, work-like setting, and measure your coding
                            accuracy and speed.
                        </p>

                        <p className="mb-4">
                            Be prepared to review each chart in sequence. Once you have submitted
                            codes for a chart, you cannot go backwards to change your answers.
                        </p>

                        <p className="mb-4">
                            Use correct coding conventions, including capitalization and punctuation
                            (i.e. diagnostic code periods).
                        </p>

                        <ol style={{ marginLeft: '1.5em' }}>
                            <li className="mb-3">
                                <strong>Diagnosis Code Identification:</strong>{' '}
                                Test your knowledge of proper medical coding, and apply all appropriate
                                coding conventions as specified by the latest ICD-10-CM Guidelines.
                            </li>
                            <li className="mb-3">
                                <strong>Procedure Code Identification:</strong>{' '}
                                For outpatient cases, identify correct Procedure Code(s), including CPT,
                                Evaluation & Management (E/M), and HCPCS Level II codes. For inpatient
                                cases, identify ICD-10-PCS codes.
                            </li>
                            <li className="mb-3">
                                <strong>Modifier Identification:</strong>{' '}
                                Apply modifier(s) to each Procedure Code as appropriate, based on CMS
                                and AHA coding conventions.
                            </li>
                        </ol>
                    </div>

                    <p>
                        While there is no formal time limit to code each chart, you must code all 10 charts to complete your session and see your results.
                        <br />
                        <br />
                        For each session, we recommend budgeting 45 to 120 minutes of uninterrupted time. Your progress for unfinished sessions will not be recorded.
                        <br />
                        <br />
                        Thanks for training with Astute Health. <b className='is-primary-txt'>Happy Coding!</b>
                    </p>
                </div>
            </Modal.Content>
        </Modal>
    );
};

export default AboutTrainerModal;