import React, { useEffect, useState } from "react";
import { Heading, Button } from "react-bulma-components";
import { AppApi } from "../../services";
import { NewUserForm, Loading } from "../../components";
import { toast } from "react-toastify";
const appApi = new AppApi();

export default (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [userProfileData, setUserProfileData] = useState(null);

    useEffect(() => {
        getProfile()
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const getProfile = async () => {
        setIsLoading(true);
        try {
            const response = await appApi.getProfile();
            if (response && response.data) {
                setUserProfileData(response.data);
            } else {
                toast.error("Error retrieving profile data.");
            }
        } catch (e) {
            toast.error("Error retrieving profile data.");
            console.error(e);
        }
        setIsLoading(false);
    }

    const handleProfileUpdate = async (updatedData) => {
        try {
            const response = await appApi.updateProfile('demographic-update', updatedData);
            if (response && response.data) {
                toast.success("Profile updated successfully!");
                // You can update the userProfileData state here if needed or trigger any other side effects.
            } else {
                toast.error("Error updating profile.");
            }
        } catch (e) {
            toast.error("Error updating profile.");
            console.error(e);
        }
    };

    const NoProfileMessage = () => (
        <div className="has-text-centered">
            <p>You need to register first.</p>
            <Button onClick={() => window.location.pathname = '/'}>Register</Button>
        </div>
    );

    return (
        <div style={{ marginBottom: '10px' }}>
            <Heading className="has-text-centered" weight="semibold" size={2}>User Profile</Heading>
            {userProfileData?.isRegistered ? (
                <NewUserForm mode="update" initialData={userProfileData} onSubmit={handleProfileUpdate} />
            ) : (
                <NoProfileMessage />
            )}
            {!!isLoading && <Loading visible={isLoading} />}
        </div>
    )
}