import VerifyModal from './VerifyModal';
import InfoModal from "./InfoModal";
import HistoryViewModal from './HistoryViewModal';
import AboutTrainerModal from './AboutTrainerModal';
import AcknowledgeModal from './AcknowledgeModal';
export default {
    VerifyModal,
    InfoModal,
    HistoryViewModal,
    AboutTrainerModal,
    AcknowledgeModal
}