import React from "react";
import { Modal } from 'react-bulma-components';
import MedicalCodingHistoryComponent from '../MedicalCodingHistoryComponent';
import { JsUtils } from "../../services";

export default (props) => {
    console.log(props.data)
    return (
        <>
            <Modal
                show={props.show}
                onClose={() => {
                    props.onClose();
                }}
                closeOnBlur={true}
                showClose={false}
            >
                <Modal.Card className="mobile-full-width" style={{ padding: '20px', width: '75%' }}>
                    <Modal.Card.Header showClose>
                        <Modal.Card.Title
                            className="is-size-4-desktop is-size-5-tablet is-size-6-mobile"
                        >{`Session${props.data && props.data.created ? `: ${JsUtils.formatDate(props.data.created)}` : ''}`}</Modal.Card.Title>
                    </Modal.Card.Header>
                    <Modal.Card.Body>
                        <MedicalCodingHistoryComponent sessionData={props?.data} />
                    </Modal.Card.Body>
                </Modal.Card>
            </Modal>
        </>
    )
}