export default class {
    static sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    static getBase64 = async (file) => {
        return new Promise((myResolve, myReject) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                console.log("success");
                myResolve(reader.result)
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
                myReject(error);
            };
        });
    }

    /**
     * @param {*} batchMethod 
     * @param {*} items 
     * @param {*} batchSize 
    */
    static batchOperation = async (batchMethod, items, batchSize = 50, sleepMs = null, logProgress = false) => {
        let batch = [];
        let results = [];
        for (let i = 0; i < items.length; i++) {
            batch.push(batchMethod(items[i]));
            if (batch.length === batchSize || i === items.length - 1) {
                let batchResults = await Promise.all(batch);
                results.push(...batchResults);
                batch = [];

                if (logProgress === true) {
                    console.log(items.length, i);
                }
                if (sleepMs != null) {
                    await this.sleep(sleepMs);
                }
            }
        }
        return results;
    }

    /**
     * @param {*} username 
     * @param {*} fileData 
     * @param {*} fileNameAddition 
     * @TODO remove original logic note: 
     * `${AuthStorage.getUserEmail().split("@")[0]}_${count++}_${new Date().toISOString()
     *   .replaceAll(':', '_').replace('.', '_')}.png`;
     */
    static fileNamer = (username, fileData, fileNameAddition = null) => {
        let extension = '.png'
        if (fileData.startsWith('data:application/pdf')) {
            extension = '.pdf'
        }

        return `${username.split("@")[0]}_${fileNameAddition}_${new Date().toISOString().replaceAll(':', '_')
            .replace('.', '_')}${extension}`;
    }

    static formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    static formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getUTCFullYear();
        // Months are 0-indexed in JS
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');

        // 12 hour format
        let hour = date.getUTCHours();
        const ampm = hour >= 12 ? 'PM' : 'AM';
        hour = hour % 12;
        hour = hour ? hour : 12;

        return `${year}-${month}-${day} ${hour}:00 ${ampm}`;
    }
}