import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Router from './layout/Router';

const App = () => {
    const [landingPageData, setLandingPageData] = useState({})
    useEffect(() => {
        // setLandingPageData(JsonData)
    }, [])

    return (
        <div style={{
            // TODO: remove idk if we need this (body settings)
            // display: 'flex',
            // minHeight: '100vh',
            // flexDirection: 'column',
            // justifyContent: 'space-between',
            // background: '#292929'
        }}>
            <Helmet
                title="Astute Health"
                meta={[
                    { name: 'description', content: 'astute health web app' },
                    { name: 'keywords', content: 'health, ai, coding' },
                ]}
                script={[
                    { 'src': 'https://use.fontawesome.com/releases/v5.4.1/js/all.js' },
                ]}
                link={[
                    { 'rel': 'stylesheet', 'href': 'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css' }
                ]}
            />
            <Router />
            <ToastContainer
                position="top-center"
            />
        </div>
    )
}
export default App;
