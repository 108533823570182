import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { AuthStorage } from '../../services';
import LOGO_SVG from '../../assets/svgs/Astute Health Main Logo.svg';

export default (props) => {
	const [isActive, setIsActive] = useState(false);
	const [isDropdownActive, setIsDropdownActive] = useState(false);
	const [isProfileActive, setIsProfileActive] = useState(false);
	const [isHistoryActive, setIsHistoryActive] = useState(false);

	useEffect(() => {

	}, [isProfileActive]);

	const toggleNav = () => {
		setIsActive(!isActive);
	}

	const toggleDropdown = () => {
		setIsDropdownActive(!isDropdownActive);
	}

	const renderTab = (path, name, onClick, isActive) => {
		if (onClick) {
			return (
				<a
					onClick={onClick}
					className={`navbar-item is-white-txt ${isActive ? 'is-active-main-tab' : ''}`}>
					{name}
				</a>
			)
		}
		return (
			<a
				href={path}
				className={`navbar-item is-white-txt ${isActive ? 'is-active-main-tab' : ''}`}>
				{name}
			</a>
		);
	}

	const renderTabs = () => {
		return (
			<div className={isActive ? 'navbar-menu is-active' : 'navbar-menu'}>
				<div className="navbar-end subtitle">
					{renderTab(null, 'Results', () => {
						window.location.replace('/results');
					}, isHistoryActive || window.location.href.includes('results'))}
					{renderTab(null, 'Profile', () => {
						window.location.replace('/profile');
					}, isProfileActive || window.location.href.includes('profile'))}
					{renderTab(null, 'Logout', () => { AuthStorage.logout(); window.location.reload() })}
				</div>
			</div>);
	}

	// TODO: WE NEED TO MANAGE ALL STYLED IN HERE, and DO SOMETHING ABOUT HAMBURGER HEIGHT MATCHUP
	return (
		<div>
			<nav
				className="navbar is-fixed-top"
				role="navigation"
				aria-label="main navigation"
			>
				<div className='container'>
					<div className="navbar-brand">
						<a className="navbar-item" style={{ marginLeft: '0px' }} href="/" >
							<img src={LOGO_SVG} style={{ maxHeight: "none", marginLeft: '0px' }} width="200" height="200" />
						</a>
						{!!props.isLoggedIn &&
							<button className={isActive
								? 'nav-hamburger button navbar-burger is-active' : 'nav-hamburger button navbar-burger'}
								onClick={toggleNav} data-target="navbarContent">
								<span className='is-primary-white-background'></span>
								<span className='is-primary-white-background'></span>
								<span className='is-primary-white-background'></span>
							</button>}
					</div>
					{!!props.isLoggedIn && renderTabs()}
				</div>
			</nav>
		</div>
	)
}