// import jwtDecode from 'jwt-decode';
// TODO: check if token is still valid if not get another get it from api
// and store it.
export default class {
    static getAuthToken = () => {
        let token = localStorage.getItem('AuthToken');

        // TODO: there is backend work to respond with appropriate expiration etc.
        // let tokenDecode = jwtDecode(token);

        // // check if expired
        // const now = new Date();
        // let isExpired = now.getTime() > tokenDecode.exp * 1000;

        // if (token == null || token == '' || isExpired) {
        //     // TODO: try to refresh it
        //     console.log("IS EXPIRED:", isExpired, tokenDecode.exp)
        // }
        return token;
    };
    static setAuthToken = (token) => {
        let setItem = localStorage.setItem('AuthToken', token);
        return setItem;
    };

    static getUserEmail = () => {
        let email = localStorage.getItem('UserEmail');

        if (!email) {
            console.log("NO EMAIL:")
        }
        return email;
    };

    static getUserProfile = () => {
        let profile = localStorage.getItem('UserProfile');

        if (!profile) {
            console.log("NO PROFILE:")
        }
        // @ts-ignore
        return JSON.parse(profile);
    };

    static setUserEmail = (email) => {
        return localStorage.setItem('UserEmail', email);
    }

    static setUserProfile = (profile) => {
        return localStorage.setItem('UserProfile', JSON.stringify(profile));
    }

    static logout = () => {
        localStorage.clear()
    }
}