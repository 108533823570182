import React, { useState, useEffect } from "react";
import { Button, Columns, Container, Form, Icon } from 'react-bulma-components';
import { toast } from 'react-toastify';
import { Loading } from "../components";
import { AppApi, AuthStorage } from '../services';
import AcknowledgeModal from "../components/Modals/AcknowledgeModal";
const appApi = new AppApi();

export default (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState();
    const [verifyCode, setVerifyCode] = useState();
    const [showVerifyInput, setShowVerifyInput] = useState(false);
    const [showAcknowledgeModal, setShowAcknowledgeModal] = useState(false);

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            // TODO: remove this, its for testing
            const loggedInEmailBypass = AuthStorage.getUserEmail();
            if (loggedInEmailBypass === 'bypass') {
                setIsLoading(false);
                props.onLoggedIn(true);
            }

            const authToken = AuthStorage.getAuthToken();
            if (!!authToken && await appApi.isAccessTokenValid()) {
                setIsLoading(false);
                props.onLoggedIn(true);
            }
            setIsLoading(false);
        })();
    }, []);

    const onSendCodeButtonPress = async () => {
        setIsLoading(true);
        // TODO: remove testing code below
        if (email === 'bypass') {
            await AuthStorage.setUserEmail('bypass');
            setIsLoading(false);
            props.onLoggedIn(true);
            return;
        }

        if (!email) {
            toast.error("Must provide valid email.");
            setIsLoading(false);
            return;
        }
        if (await appApi.sendAccessCode(email)) {
            // Show inline verification input instead of modal
            setShowVerifyInput(true);
            setIsLoading(false);
            return;
        }
        setIsLoading(false);
        toast.error('Failure, Try Again.');
    };

    const onAcknowledgeAction = async () => {
        const result = await appApi.postUserAcknowledged(email, verifyCode);
        if (!result) {
            toast.error("Something went wrong please try again.")
            return;
        }
        setShowAcknowledgeModal(false);
        await onVerifyCodeButtonPress();
    }

    const onVerifyCodeButtonPress = async () => {
        setIsLoading(true);

        const isUserAcknowledgedResult = await appApi.isUserAcknowledged(email, verifyCode);
        if (!isUserAcknowledgedResult.isUserAcknowledged) {
            setShowAcknowledgeModal(true);
            setIsLoading(false);
            return;
        }
        const verifyResult = await appApi.verifyLoginAccessCode(email, verifyCode);
        if (verifyResult) {
            await AuthStorage.setAuthToken(verifyResult.authToken);
            await AuthStorage.setUserEmail(email);
            props.onLoggedIn(true);
            setIsLoading(false);
            return;
        }
        setIsLoading(false);
        toast.error('Failure To Authenticate.');
    };

    return (
        <div className="centered is-primary-white-background" style={{ padding: '20px' }}>
            <Container>
                {/* Banner Section */}
                <section className="section is-small pt-4 pb-4">
                    <div className="box is-thirdary-bg is-primary-dark-txt has-text-centered" style={{ maxWidth: '640px', margin: '0 auto' }}>
                        <h1 style={{ fontSize: '24px', textAlign: 'center' }}>
                            Welcome to The Trainer®
                        </h1>
                        <p className="is-size-6">
                            Showcase your knowledge in medical coding, one chart at a time.
                        </p>
                    </div>
                </section>
                {/* Login Section */}
                <Columns className="is-centered">
                    {!!isLoading && <Loading visible={isLoading} />}
                    {!isLoading &&
                        <Columns.Column className="is-5-tablet is-4-desktop is-4-widescreen">
                            <div className="box has-text-centered is-quadary-bg">
                                <Form.Field>
                                    <Form.Label className="has-text-centered is-primary-txt" size={'large'}>Login</Form.Label>
                                    <Form.Control>
                                        <Form.Input
                                            placeholder="Email"
                                            name="name"
                                            value={email}
                                            onChange={(event) => { setEmail(event.target.value) }}
                                        />
                                        <Icon align="left">
                                            <span className="icon">
                                                <i className="fas fa-user"></i>
                                            </span>
                                        </Icon>
                                    </Form.Control>
                                </Form.Field>

                                {showVerifyInput ? (
                                    <Form.Field kind="group" className="is-fullwidth">
                                        <Form.Control className="is-expanded">
                                            <Form.Input
                                                placeholder="Verification Code"
                                                name="verifyCode"
                                                value={verifyCode}
                                                onChange={(event) => { setVerifyCode(event.target.value) }}
                                                className="is-fullwidth"
                                            />
                                        </Form.Control>
                                        <Form.Control className="is-expanded">
                                            <Button className="is-fullwidth" color="primary" onClick={onVerifyCodeButtonPress}>Verify</Button>
                                        </Form.Control>
                                    </Form.Field>
                                ) : (
                                    <Button.Group>
                                        <Button
                                            fullwidth
                                            rounded
                                            color="primary"
                                            onClick={onSendCodeButtonPress}>Sign Up / Login</Button>
                                    </Button.Group>
                                )}
                            </div>
                        </Columns.Column>}
                </Columns>
                <AcknowledgeModal
                    isOpen={showAcknowledgeModal}
                    onClose={() => { setShowAcknowledgeModal(false) }}
                    onAcknowledge={onAcknowledgeAction}
                />
            </Container>
        </div >
    );
}