import React, { useState, useEffect } from 'react';
import { Container } from 'react-bulma-components';
import MedicalCodingComponent from './MedicalCodingComponent';
import Loading from './Loading';
import { AppApi, JsUtils } from "../services";
import { toast } from 'react-toastify';

const appApi = new AppApi();

export default ({ sessionData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [currentNoteIndex, setCurrentNoteIndex] = useState(0);
    const [historyData, setHistoryData] = useState([]);

    const getTestPercentage = (diagScore, cptScore) => {
        const [achievedDiag, totalDiag] = diagScore ? diagScore.split('/').map(Number) : [0, 0];
        const [achievedCPT, totalCPT] = cptScore ? cptScore.split('/').map(Number) : [0, 0];

        if (totalDiag + totalCPT === 0) return 0;
        return ((achievedDiag + achievedCPT) / (totalDiag + totalCPT)) * 100;
    };

    useEffect(() => {
        const fetchSessionHistory = async () => {
            setIsLoading(true);
            try {
                const response = await appApi.getUserSessionTestResults(sessionData.sessionTestIds);
                setHistoryData(response?.data.items || []);
            } catch (error) {
                toast.error("Error fetching history.");
                console.error("Failed to fetch session history:", error);
            }
            setIsLoading(false);
        };

        fetchSessionHistory();
    }, [sessionData.sessionTestIds]);

    const handleBackAction = () => {
        setCurrentNoteIndex(prev => Math.max(0, prev - 1));
    };

    const handleForwardAction = () => {
        setCurrentNoteIndex(prev => Math.min(historyData.length - 1, prev + 1));
    };

    const isSectionPassed = (score) => {
        if (!score) {
            return true;
        }
        console.log('score', score)
        const [achieved, total] = score.split('/').map(Number);
        return achieved === total;
    };

    const isSessionPassed = (cptScore, diagScore) => {
        return isSectionPassed(cptScore) && isSectionPassed(diagScore);
    };

    const overallStatusColor = isSessionPassed(historyData[currentNoteIndex]?.cptScore, historyData[currentNoteIndex]?.diagScore) ? 'green' : 'red';
    const overallStatus = isSessionPassed(historyData[currentNoteIndex]?.cptScore, historyData[currentNoteIndex]?.diagScore) ? 'PASS' : 'MISS';

    return (
        <Container>
            {!!isLoading && <Loading visible={isLoading} />}

            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '10px' }}>
                <button
                    className="backButton button is-outlined"
                    onClick={handleBackAction}
                    disabled={currentNoteIndex === 0}
                >
                    Back
                </button>
                <button
                    className="forwardButton button is-outlined"
                    onClick={handleForwardAction}
                    disabled={currentNoteIndex === historyData.length - 1}
                >
                    Forward
                </button>
            </div>

            {/* First Row */}
            <div className="columns is-centered tight-columns">
                <div className="column is-narrow">
                    <p><strong>Note:</strong> {currentNoteIndex + 1} of {historyData.length}</p>
                </div>
            </div>

            {/* Second Row */}
            <div className="columns tight-columns">
                <div className="column">
                    <strong>{"Session Result: "}</strong>
                    <span>{`${sessionData?.overallPercentScore.toFixed(2)}% `}</span>
                    <span className={sessionData?.overallPercentScore === 100 ? 'has-text-success' : 'has-text-danger'}>
                        {sessionData?.overallPercentScore === 100 ? 'PASS' : 'MISS'}
                    </span>
                </div>
                <div className="column has-text-right">
                    <p className="has-text-right">
                        <p><strong>Total Time:</strong> {JsUtils.formatTime(sessionData?.sessionTime || 0)}</p>
                    </p>
                </div>
            </div>

            {/* Third Row */}
            <div className="columns tight-columns">
                <div className="column">
                    <strong>Chart Result:</strong>
                    <span>
                        <span style={{ color: 'black' }}>
                            {` ${getTestPercentage(historyData[currentNoteIndex]?.diagScore, historyData[currentNoteIndex]?.cptScore).toFixed(2)}%`}
                        </span>
                        <span style={{ color: overallStatusColor }}>
                            {` ${overallStatus}`}
                        </span>
                    </span>
                </div>
                <div className="column has-text-right">
                    <p><strong>Chart Time:</strong> {JsUtils.formatTime(historyData[currentNoteIndex]?.seconds || 0)}</p>
                </div>
            </div>

            {/* Fourth Row */}
            <div className="columns tight-columns">
                <div className="column">
                    <span><strong>Diag Score:</strong> {historyData[currentNoteIndex]?.diagScore}</span>
                </div>
                <div className="column has-text-right">
                    <span><strong>CPT Score:</strong> {historyData[currentNoteIndex]?.cptScore}</span>
                </div>
            </div>

            <div style={{ marginBottom: '10px' }} ></div>
            {historyData.length > 0 && (
                <MedicalCodingComponent
                    key={currentNoteIndex}
                    chartNoteIndex={currentNoteIndex}
                    totalChartNotes={historyData?.length}
                    sessionTestId={historyData[currentNoteIndex]?.sk || ""}
                    chartNote={historyData[currentNoteIndex]?.chartNote || ""}
                    icdCodes={historyData[currentNoteIndex]?.diags || ['']}
                    cptCodes={historyData[currentNoteIndex]?.cpts || ['']}
                    correctIcdCodes={historyData[currentNoteIndex]?.correctDiags || []}
                    correctCptCodes={historyData[currentNoteIndex]?.correctCpts || []}
                    viewMode={true}
                    // this only applies to history aka viewMode
                    feedback={historyData[currentNoteIndex]?.feedback || ""}
                />
            )}
        </Container>
    );
};